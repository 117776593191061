import {
  FC,
  useEffect,
  useState
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TonConnectButton } from '@tonconnect/ui-react';

import {
  ReactComponent as IconFour,
} from '../../icons/icon_4.svg';
import {
  ReactComponent as IconFive,
} from '../../icons/icon_5.svg';
import {
  ReactComponent as IconSix,
} from '../../icons/icon_6.svg';
import BaronLogo from "../../images/baron_logo.png";
import { useTonConnectUI } from '@tonconnect/ui-react';
import { Buffer } from 'buffer';

import {Address, beginCell, toNano} from 'ton-core';
import {JettonMaster, TonClient} from "ton";

global.Buffer = window.Buffer || Buffer;

export const SwapPanel: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tonConnectUI] = useTonConnectUI();

  const [SwapAmount, setSwapAmount] = useState<number>(100000);
  const [isSticky, setSticky] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    setSticky(window.scrollY >= 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('OpenMobileMenus');
    } else {
      document.body.classList.remove('OpenMobileMenus');
    }
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const client = new TonClient({
    endpoint: 'https://toncenter.com/api/v2/jsonRPC',
  });

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const navigateToHome = () => {
    navigate("/");
  };

  const buy = async (amount: number) => {
    const userAddress = tonConnectUI.account?.address;
    if (!userAddress){
        alert("You need connect wallet");
        return;
    }

    const userAddressParsed = Address.parse(userAddress);
    const contractAddressParsed = Address.parse("EQDzgkVu5o9ov6jwI5aC-exrisgOLOIuXmB-ejVRaO_bFUYL");

    const old_masterContract_code = JettonMaster.create(Address.parse("EQBl3gg6AAdjgjO2ZoNU5Q5EzUIl8XMNZrix8Z5dJmkHUfxI"));
    const old_masterContract = await client.open(old_masterContract_code);
    const old_jetton_wallet_address = await old_masterContract.getWalletAddress(userAddressParsed);

    const body = beginCell()
        .storeUint(0xf8a7ea5, 32)
        .storeUint(0, 64)
        .storeCoins(toNano(amount))
        .storeAddress(contractAddressParsed)
        .storeAddress(userAddressParsed)
        .storeUint(0, 1)
        .storeCoins(toNano("0.4")) // 0.1 
        .storeUint(0, 1)
        .storeUint(0, 32)
        .endCell();

    const transaction = {
      messages: [
          {
              address: old_jetton_wallet_address.toString(),
              amount: toNano("0.5") + "", // 0.15
              payload: body.toBoc().toString("base64")
          }
      ],
      validUntil: Math.floor(Date.now() / 1000) + 360
    }

    try {
      tonConnectUI.sendTransaction(transaction);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
  <div className="main_header">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <nav className="navbar navbar-light navbar-expand-lg">
            <a onClick={navigateToHome} className="navbar-brand">
              <img src={BaronLogo} alt="" />
            </a>
            <TonConnectButton style={{ right: 0, left: "auto", position: "absolute", display: "block" }} />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsingNavbar3"
            >
              <IconFour/>
            </button>
            <div
              className="navbar-collapse collapse w-100"
              id="collapsingNavbar3"
            >
              <ul className="navbar-nav  w-100">
                {/* <li class="nav-item" style="visibility: hidden;">
                              <a class="nav-link" href="#">Apply for IDO</a>
                          </li> */}
                <li className="ConnectWallet">
                  <TonConnectButton style={{ float: "right" }} />
                </li>
              </ul>
            </div>

          </nav>
        </div>
      </div>
    </div>
  </div>
  <div className="MobileMenusSection">
    <button id="closedMenusButtons" onClick={toggleMobileMenu}>
      <i className="fa fa-times" />
    </button>
    <ul>
      <li className="nav-item">
        <a className="" onClick={navigateToHome}>
          Main page
        </a>
      </li>
      <li className="GoToTheApp">
        <TonConnectButton style={{ float: "right" }} />
      </li>
    </ul>
  </div>
  <div className="mainSectionContainer mainSectionSwapContainer">
    <div className="mainSectionContainer_overlay">
    <svg
        viewBox="0 0 1440 808"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_f)">
            <path
              d="M1616.34 486.418C1514.12 557.5 1415.31 520.249 1317.35 597.096C1119.56 752.255 1809.42 797.582 1961.07 597.096C2075.79 445.445 2150.99 100.775 1961.07 110.189C1821.69 117.097 1831.24 286.464 1731.25 383.81C1688.15 425.778 1665.74 452.071 1616.34 486.418Z"
              fill="#E2DBFD"
            />
          </g>
          <g filter="url(#filter1_f)">
            <path
              d="M46.5825 35.1383C-34.4485 87.1044 -25.455 227.076 -114.204 250.09C-267.048 289.726 -185.905 -85.5899 -114.204 -263.795C24.2469 -607.896 736.991 -637.274 656.715 -263.795C622.815 -106.073 573.642 23.0871 449.713 59.6328C359.745 86.1639 312.083 -12.8444 219.797 -10.8513C150.738 -9.35995 108.402 -4.50757 46.5825 35.1383Z"
              fill="#649139"
            />
          </g>
          <g filter="url(#filter2_f)">
            <path
              d="M117.555 201.716C49.2335 266.578 92.8196 359.14 9.6155 406.974C-133.68 489.353 -145.125 203.11 -117.334 55.2079C-63.6696 -230.382 642.947 -508.123 654.808 -223.451C659.817 -103.234 642.473 2.95386 527.376 72.7659C443.819 123.447 371.624 72.9019 279.683 107.624C210.883 133.607 169.679 152.232 117.555 201.716Z"
              fill="url(#paint0_linear)"
            />
          </g>
          <g filter="url(#filter3_f)">
            <ellipse
              rx={492}
              ry="326.5"
              transform="matrix(1 0 0 -1 515 -250.5)"
              fill="#e6e6fa"
            />
          </g>
          <g opacity="0.6" filter="url(#filter4_f)">
            <ellipse
              rx={378}
              ry={251}
              transform="matrix(1 0 0 -1 873 -103)"
              fill="#7bb771"
            />
          </g>
          <g filter="url(#filter5_f)">
            <circle
              r="176.5"
              transform="matrix(1 0 0 -1 1290.5 -149.5)"
              fill="#d6e33c"
            />
          </g>
          <g filter="url(#filter6_f)">
            <ellipse
              rx={492}
              ry="326.5"
              transform="matrix(1 0 0 -1 89 -206.5)"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f"
            x={932}
            y={-240}
            width={1495}
            height={1321}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter1_f"
            x={-551}
            y={-883}
            width={1564}
            height={1486}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter2_f"
            x="-620.771"
            y={-760}
            width="1759.54"
            height="1550.34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter3_f"
            x={-327}
            y={-927}
            width={1684}
            height={1353}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter4_f"
            x={345}
            y={-504}
            width={1056}
            height={802}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur stdDeviation={75} result="effect1_foregroundBlur" />
          </filter>
          <filter
            id="filter5_f"
            x={864}
            y={-576}
            width={853}
            height={853}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={125}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter6_f"
            x={-753}
            y={-883}
            width={1684}
            height={1353}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="356.086"
            y1="284.187"
            x2={142}
            y2={-66}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E3FEA" stopOpacity="0.65" />
            <stop offset={1} stopColor="#7E3FEA" stopOpacity={0} />
          </linearGradient>
          <clipPath id="clip0">
            <rect
              width={1440}
              height={971}
              fill="white"
              transform="matrix(1 0 0 -1 0 808)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div className="container">
      <div className="swap-panel">
        <div className="swap-container">
          <div className="input-group">
            <label htmlFor="fromAmount">From (LAVE)</label>
            <input
              type="number"
              id="fromAmount"
              value={SwapAmount}
              placeholder="Enter amount"
              onChange={(e) => {
                let newValue = (e?.currentTarget.value || "").replace(
                  /[^\d.]+/g,
                  ""
                );

                if (newValue) {
                  const numberValue = Number(newValue);
                  if (!isNaN(numberValue)) {
                    setSwapAmount(numberValue);
                  }
                }
              }}
            />
          </div>
          <div className="input-group">
            <label htmlFor="toAmount">To (BARON)</label>
            <input type="number" id="toAmount" value={SwapAmount*10} placeholder={"0"} readOnly={true} />
          </div>
          <p className="text-sm-center">1 LAVE = 10 BARO</p>
          <button onClick={() => {buy(SwapAmount)}} className="button" id="swap_button">
            SWAP
          </button>
          {/* <div id="output" className="output" /> */}
        </div>
      </div>
    </div>
  </div>
</>

  );
};