import { createBrowserRouter } from "react-router-dom";

import { ROUTE_NAMES } from "./constants";

import {
  SwapPanel,
  MainPanel,
} from "../panels";

export const router = createBrowserRouter([
  {
    path: ROUTE_NAMES.MAIN,
    element: <MainPanel />,
  },
  {
    path: ROUTE_NAMES.SWAP,
    element: <SwapPanel />,
  }
]);

router.subscribe((v) => {

});
