import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  ReactComponent as IconOne,
} from '../../icons/icon_1.svg';
import {
  ReactComponent as IconTwo,
} from '../../icons/icon_2.svg';
import {
  ReactComponent as Eye,
} from '../../icons/Eye.svg';
import {
  ReactComponent as IconThree,
} from '../../icons/icon_3.svg';

import "./Main.module.css";

import Logo from "./../../images/logo.png";
import Torus_icons from "../../images/Torus_icons.png";
import Prism_icons from "../../images/Prism_icons.png";
import Polyhedron_icon from "../../images/Polyhedron_icon.png";
import Bhanu from "../../images/Team/Bhanu.jpg";
import Monister from "../../images/Monister.png";
import BlackEdge from "../../images/Black edge.png";
import Spark from "../../images/Spark.png";
import Dropsd from "../../images/Dropsd.png";
import Cube_rightTopSide from "../../images/Cube_rightTopSide.png";
import Cube_rightBottomSide from "../../images/Cube_rightBottomSide.png";
import Cube_LeftTopSide from "../../images/Cube_LeftTopSide.png";
import CubeLeftBottomside from "../../images/CubeLeftBottomside.png";
import RoadMapBottomImages from "../../images/RoadMapBottomImages.png";
import roadMapBoneImage from "../../images/roadMapBoneImage.png";
import {ReactComponent as line} from "../../images/line.svg";
import OurTeamCube from "../../images/OurTeamCube.png";
import backers_afterImage from "../../images/backers_afterImage.png";
import POLYSTARTER_arrow from "../../images/POLYSTARTER_arrow.png";
import OurKeyFeatures from "../../images/OurKeyFeatures.png";
import POLYGON from "../../images/POLYGON.png";
import PolygonBullet_icon from "../../images/PolygonBullet_icon.png";
import PolygonBullet_icon_2 from "../../images/PolygonBullet_icon_2.png";
import POLYSTARTER from "../../images/POLYSTARTER.png";
import baron_token from "../../images/baron_token.png";
import Q2_2021 from "../../images/Q2_2021.png";
import footerLogo from "../../images/footerLogo.png";
import Q4_2021 from "../../images/Q4-2021.png";
import Q3_2021 from "../../images/Q3_2021.png";


export const MainPanel: FC = () => {
  const navigate = useNavigate();
  const [isSticky, setSticky] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    setSticky(window.scrollY >= 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add('OpenMobileMenus');
    } else {
      document.body.classList.remove('OpenMobileMenus');
    }
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigateToSwap = () => {
    navigate("/swap");
  };

  return (
    <>
  <div className={`main_header ${isSticky ? 'fixedHeader' : ''}`}>
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <nav className="navbar navbar-light navbar-expand-lg">
            <a href="/" className="navbar-brand">
              <img src={Logo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsingNavbar3"
            >
              <IconOne/>
            </button>
            <div
              className="navbar-collapse collapse w-100"
              id="collapsingNavbar3"
            >
              <ul className="navbar-nav  w-100">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#FindTheRightPlan"
                  >
                    Built for
                  </a>
                </li>
                {/* <li class="nav-item" style="visibility: hidden;">
                              <a class="nav-link" href="#">Apply for IDO</a>
                          </li> */}
                <li className="GoToTheApp">
                  <a href="#tiersThatSection">Start</a>
                </li>
              </ul>
            </div>
            <div className="MobileToggleButton">
              <button className="navbar-togglerMobile" type="button" onClick={toggleMobileMenu}>
                <IconTwo/>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div className="MobileMenusSection">
    <button id="closedMenusButtons"  onClick={toggleMobileMenu}>
      <i className="fa fa-times" />
    </button>
    <ul>
      <li className="nav-item">
        <a className="" href="#FindTheRightPlan" onClick={toggleMobileMenu}>
          Built for
        </a>
      </li>
      <li className="GoToTheApp">
        <a href="#tiersThatSection" onClick={toggleMobileMenu}>Start</a>
      </li>
    </ul>
  </div>
  <div className="mainSectionContainer">
    <div className="mainSectionContainer_overlay">
      <svg
        viewBox="0 0 1440 808"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_f)">
            <path
              d="M1616.34 486.418C1514.12 557.5 1415.31 520.249 1317.35 597.096C1119.56 752.255 1809.42 797.582 1961.07 597.096C2075.79 445.445 2150.99 100.775 1961.07 110.189C1821.69 117.097 1831.24 286.464 1731.25 383.81C1688.15 425.778 1665.74 452.071 1616.34 486.418Z"
              fill="#E2DBFD"
            />
          </g>
          <g filter="url(#filter1_f)">
            <path
              d="M46.5825 35.1383C-34.4485 87.1044 -25.455 227.076 -114.204 250.09C-267.048 289.726 -185.905 -85.5899 -114.204 -263.795C24.2469 -607.896 736.991 -637.274 656.715 -263.795C622.815 -106.073 573.642 23.0871 449.713 59.6328C359.745 86.1639 312.083 -12.8444 219.797 -10.8513C150.738 -9.35995 108.402 -4.50757 46.5825 35.1383Z"
              fill="#0038FF"
            />
          </g>
          <g filter="url(#filter2_f)">
            <path
              d="M117.555 201.716C49.2335 266.578 92.8196 359.14 9.6155 406.974C-133.68 489.353 -145.125 203.11 -117.334 55.2079C-63.6696 -230.382 642.947 -508.123 654.808 -223.451C659.817 -103.234 642.473 2.95386 527.376 72.7659C443.819 123.447 371.624 72.9019 279.683 107.624C210.883 133.607 169.679 152.232 117.555 201.716Z"
              fill="url(#paint0_linear)"
            />
          </g>
          <g filter="url(#filter3_f)">
            <ellipse
              rx={492}
              ry="326.5"
              transform="matrix(1 0 0 -1 515 -250.5)"
              fill="#e6e6fa"
            />
          </g>
          <g opacity="0.6" filter="url(#filter4_f)">
            <ellipse
              rx={378}
              ry={251}
              transform="matrix(1 0 0 -1 873 -103)"
              fill="#cc0000"
            />
          </g>
          <g filter="url(#filter5_f)">
            <circle
              r="176.5"
              transform="matrix(1 0 0 -1 1290.5 -149.5)"
              fill="#5956E9"
            />
          </g>
          <g filter="url(#filter6_f)">
            <ellipse
              rx={492}
              ry="326.5"
              transform="matrix(1 0 0 -1 89 -206.5)"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f"
            x={932}
            y={-240}
            width={1495}
            height={1321}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter1_f"
            x={-551}
            y={-883}
            width={1564}
            height={1486}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter2_f"
            x="-620.771"
            y={-760}
            width="1759.54"
            height="1550.34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter3_f"
            x={-327}
            y={-927}
            width={1684}
            height={1353}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter4_f"
            x={345}
            y={-504}
            width={1056}
            height={802}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur stdDeviation={75} result="effect1_foregroundBlur" />
          </filter>
          <filter
            id="filter5_f"
            x={864}
            y={-576}
            width={853}
            height={853}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={125}
              result="effect1_foregroundBlur"
            />
          </filter>
          <filter
            id="filter6_f"
            x={-753}
            y={-883}
            width={1684}
            height={1353}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation={175}
              result="effect1_foregroundBlur"
            />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="356.086"
            y1="284.187"
            x2={142}
            y2={-66}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7E3FEA" stopOpacity="0.65" />
            <stop offset={1} stopColor="#7E3FEA" stopOpacity={0} />
          </linearGradient>
          <clipPath id="clip0">
            <rect
              width={1440}
              height={971}
              fill="white"
              transform="matrix(1 0 0 -1 0 808)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="mainTextContent">
            <h1>
              First Decentralized
              <br className="removeBrTag" /> <span>Launchpad for Projects</span>
              <br className="removeBrTag" /> of LAVE <strong>Ecosystem</strong>
            </h1>
            <p>
              LaveStarter is a launchpad empowering your ideas to raise capital
              in a fair, transparent and decentralized way
            </p>
            <div className="subscribeSection">
              <a
                onClick={navigateToSwap}
                className="viewPoolButtons"
              >
                <span>Buy $BARO</span>
                <Eye/>
              </a>
              <a
                href="https://t.me/lavefoundation"
                className="viewPoolButtons upcomingPoolsButton"
              >
                <span>Subscribe to Channel</span>
                <IconThree/>
              </a>
              {/* <div class="subscribeHands">
                          <img src={require("../../images/Subscribe_Hands.png")} alt="">
                          <p><a href="">Subscribe to upcoming pools </a></p>
                      </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="videoSection">
            <div className="cubeBoxOne">
              <img src={Cube_LeftTopSide} alt="" />
            </div>
            <div className="cubeBoxTwo">
              <img src={Cube_rightTopSide} alt="" />
            </div>
            <video autoPlay={true} loop={true} muted={true} playsInline={true}>
              <source src={"/videos/intro.mp4"} type="video/mp4" />
              <source src={"/videos/intro.mp4"} type="video/ogg" />
            </video>
            <div className="cubeBoxThree">
              <img src={CubeLeftBottomside} alt="" />
            </div>
            <div className="cubeBoxFour">
              <img src={Cube_rightBottomSide} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="topSocialMediaSeciton">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="contentMedia">
            <ul>
              {/* <li><a href="https://www.linkedin.com/company/LaveStarterofficial/?viewAsMember=true" target="_blank"><i class="fa fa-linkedin"></i></a></li> */}
              <li>
                <a href="https://twitter.com/lave_token" target="_blank">
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://t.me/Lave_eng" target="_blank">
                  <i className="fa fa-paper-plane" />
                </a>
              </li>
              {/* <li><a href="https://www.facebook.com/LaveStarter.Official/?ref=pages_you_manage" target="_blank"><i class="fa fa-facebook"></i></a></li> */}
              {/* <li><a href="https://www.instagram.com/LaveStarter/" target="_blank"><i class="fa fa-instagram"></i></a></li> */}
              {/* <li><a href="https://www.reddit.com/user/LaveStarterOfficial" target="_blank"><i class="fa fa-reddit"></i></a></li> */}
              <li>
                <a
                  href="https://LaveStarterofficial.medium.com/"
                  target="_blank"
                >
                  <i className="fa fa-medium" />
                </a>
              </li>
              <li>
                <span>FOLLOW US</span>
              </li>
            </ul>
            <div className="TopPoweredBy">
              <span>Powered by LaveStarter</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="tiersThatSection" id="tiersThatSection">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="tiersHeading">
            <h1>
              Upcoming <span>Projects.</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="baronico col-md-3 col-sm-6">
          <div className="tiersBackgroundSection tierOne" onClick={() => {navigateToSwap()}}>
            <div className="tiersIcons">
              <img src={baron_token} alt="" />
            </div>
            <div className="tierTextContents">
              <span>BARO</span>
              <h2>Baron Token</h2>
              <p>от цыганского слова baro — «большой, великий».</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="tiersBackgroundSection tiertwo">
            <div className="tiersIcons">
              <img src={Torus_icons} alt="" />
            </div>
            <div className="tierTextContents">
              <span>Soon</span>
              <h2>Soon</h2>
              <p>Soon of LAVE ecosystem</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="tiersBackgroundSection tierthree">
            <div className="tiersIcons">
              <img src={Prism_icons} alt="" />
            </div>
            <div className="tierTextContents">
              <span>Soon</span>
              <h2>Soon</h2>
              <p>Soon of LAVE ecosystem</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="tiersBackgroundSection tierFour">
            <div className="tiersIcons">
              <img src={Polyhedron_icon} alt="" />
            </div>
            <div className="tierTextContents">
              <span>Soon</span>
              <h2>Soon</h2>
              <p>Soon of LAVE ecosystem</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="keyFeatureSection">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="keyFeaturesText">
            <h2>
              Our Key <span>Features</span>
            </h2>
            <p>
              LaveStarter combines TON’s low cost transactions and high
              throughput with its own innovative solutions to offer a unique
              feature set for both projects and investors.
            </p>
            <img src={OurKeyFeatures} alt="" />
          </div>
        </div>
        <div className="col-lg-6 desktopViewSection">
          <div className="keyFeatursPoints">
            <div className="featureTextContent">
              <div className="featureCircle">
                {/* <img src={require("../../images/FairDecentralized.png")} alt=""> */}
                <svg
                  width={64}
                  height={64}
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-0.000976562"
                    y="-0.000488281"
                    width={64}
                    height={64}
                    rx={32}
                    fill="white"
                  />
                  <path
                    d="M37.6752 29.9123C39.2333 30.8763 39.2333 33.1227 37.6752 34.0868L15.8049 47.6192C14.0025 48.7344 11.7092 47.2414 12.0294 45.1611L13.9981 32.3703C14.036 32.1245 14.036 31.8745 13.9981 31.6287L12.0294 18.8379C11.7092 16.7576 14.0025 15.2646 15.8049 16.3799L37.6752 29.9123Z"
                    fill="#706DF7"
                  />
                  <g filter="url(#filter0_bi)">
                    <path
                      d="M50.8305 29.9123C52.3885 30.8763 52.3885 33.1227 50.8305 34.0868L28.9602 47.6192C27.1578 48.7344 24.8645 47.2414 25.1847 45.1611L27.1534 32.3703C27.1912 32.1245 27.1912 31.8745 27.1534 31.6287L25.1847 18.8379C24.8645 16.7576 27.1578 15.2646 28.9602 16.3799L50.8305 29.9123Z"
                      fill="#FFA78D"
                      fillOpacity="0.5"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_bi"
                      x="21.1543"
                      y="11.9995"
                      width="34.8445"
                      height={40}
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImage" stdDeviation={2} />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.2" dy="0.1" />
                      <feGaussianBlur stdDeviation="0.5" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="featureHeadingTextContent">
                <h3>Fair and Decentralized Fundraise </h3>
                <p>
                  With fundraising being a crucial step to fuel product growth
                  and development, we provide the best decentralized fundraising
                  platform for project’s ideas to transform into reality
                </p>
              </div>
            </div>
            <div className="featureTextContent">
              <div className="featureCircle">
                {/* <img src={require("../../images/AccessToIDOs.png")} alt=""> */}
                <svg
                  width={64}
                  height={65}
                  viewBox="0 0 64 65"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-0.000976562"
                    y="0.998779"
                    width={64}
                    height={64}
                    rx={32}
                    fill="white"
                  />
                  <g filter="url(#filter0_bi)">
                    <path
                      d="M48.5457 20.6273C48.9854 18.7221 47.2779 17.0146 45.3727 17.4543L18.6294 23.6258C16.4254 24.1345 15.8345 26.995 17.6566 28.3353L28.8603 36.576C29.0756 36.7344 29.2656 36.9244 29.424 37.1397L37.6647 48.3434C39.005 50.1655 41.8655 49.5746 42.3742 47.3706L48.5457 20.6273Z"
                      fill="#FFA78D"
                      fillOpacity="0.5"
                    />
                  </g>
                  <g filter="url(#filter1_bi)">
                    <path
                      d="M35.0851 30.7921C32.994 28.701 29.6037 28.701 27.5126 30.7921L17.7865 40.5182C15.6954 42.6093 15.6954 45.9996 17.7865 48.0907C19.8776 50.1818 23.2679 50.1818 25.359 48.0907L35.0851 38.3646C37.1762 36.2735 37.1762 32.8832 35.0851 30.7921Z"
                      fill="#706DF7"
                      fillOpacity="0.7"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_bi"
                      x="11.4766"
                      y={2}
                      width="52.5235"
                      height="52.5235"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImage" stdDeviation={2} />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.2" dy="0.1" />
                      <feGaussianBlur stdDeviation="0.5" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow"
                      />
                    </filter>
                    <filter
                      id="filter1_bi"
                      x={10}
                      y="23.0059"
                      width="32.8711"
                      height="32.8711"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feGaussianBlur in="BackgroundImage" stdDeviation={2} />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.2" dy="0.1" />
                      <feGaussianBlur stdDeviation="0.5" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="featureHeadingTextContent">
                <h3>Access to IDOs of Lavandos Projects </h3>
                <p>
                  Providing a user friendly gateway for users to seamlessly
                  access and invest in well-vetted projects building in the LAVE
                  Ecosystem
                </p>
              </div>
            </div>
            <div className="featureTextContent">
              <div className="featureCircle">
                {/* <img src={require("../../images/LotteryTiers.png")} alt=""> */}
                <svg
                  width={64}
                  height={65}
                  viewBox="0 0 64 65"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width={64}
                    height={64}
                    rx={32}
                    transform="matrix(-1 0 0 1 63.998 0.998779)"
                    fill="white"
                  />
                  <path
                    d="M32.0003 43.9091L19.8862 31.8543C19.0037 30.9718 18.5166 29.7944 18.5166 28.541C18.5166 27.2922 19 26.1194 19.8771 25.2359C19.6739 23.8232 20.1152 22.3346 21.1991 21.2497C21.2009 21.2479 21.2028 21.246 21.2046 21.2433C21.0087 19.8352 21.45 18.3548 22.5303 17.2744C22.8965 16.9082 23.4898 16.9091 23.856 17.2735L32.3137 25.7111C32.5884 25.9866 40.8869 33.7348 40.7276 34.0909C40.5665 34.448 40.3921 35.5729 40.0003 35.5455C38.0905 35.4301 41.3297 38.9444 40.0003 40.2728C38.6709 41.6003 36.9704 39.1048 37.0912 41C37.1151 41.39 36.3574 43.0216 36.0003 43.1818C35.6555 43.3388 32.2813 44.1916 32.0003 43.9091Z"
                    fill="#706DF7"
                  />
                  <path
                    d="M36.4593 46.4846C35.205 46.4846 34.0276 45.9975 33.1432 45.1131L29.9956 41.9829C29.7191 41.7073 29.6422 41.2898 29.8016 40.9337C29.9627 40.5776 30.3252 40.3679 30.7152 40.3826C32.5939 40.5053 34.4598 39.8095 35.7873 38.481C37.1167 37.1526 37.8106 35.3041 37.6897 33.409C37.6659 33.019 37.8838 32.6555 38.239 32.4953C38.5961 32.3332 38.9984 31.9972 39.2731 32.2727L47.7258 41.1443C48.092 41.5105 48.092 42.1039 47.7258 42.4691C46.6473 43.5485 45.1788 43.9953 43.756 43.7948C43.7542 43.7966 43.7523 43.7985 43.7505 43.8003C42.6665 44.8834 41.1889 45.3274 39.7643 45.1232C38.8818 46.0012 37.7099 46.4846 36.4593 46.4846Z"
                    fill="#706DF7"
                  />
                  <g filter="url(#filter0_bi)">
                    <g filter="url(#filter1_bi)">
                      <path
                        d="M25.0106 40.1105C21.7075 36.8074 21.7412 31.4553 25.0106 28.1912C28.2516 24.9467 33.6055 24.8656 36.929 28.1912C40.6338 31.896 39.7796 37.263 36.929 40.1105C33.6982 43.3414 28.3497 43.4475 25.0106 40.1105Z"
                        fill="#FFA78D"
                        fillOpacity="0.5"
                      />
                    </g>
                  </g>
                  <path
                    d="M16.2746 48.7254C15.9085 48.3592 15.9085 47.7659 16.2746 47.3997L22.0247 41.6496C22.3909 41.2835 22.9842 41.2835 23.3504 41.6496C23.7166 42.0158 23.7166 42.6091 23.3504 42.9753L17.6003 48.7254C17.2341 49.0916 16.6408 49.0916 16.2746 48.7254Z"
                    fill="#706DF7"
                  />
                  <path
                    d="M21.8996 48.7254C21.5335 48.3592 21.5335 47.7658 21.8996 47.3997L25.7747 43.5246C26.1409 43.1585 26.7342 43.1585 27.1004 43.5246C27.4665 43.8908 27.4665 44.4841 27.1004 44.8503L23.2253 48.7254C22.8591 49.0915 22.2658 49.0915 21.8996 48.7254Z"
                    fill="#706DF7"
                  />
                  <path
                    d="M16.2746 43.1004C15.9085 42.7342 15.9085 42.1408 16.2746 41.7747L20.1497 37.8996C20.5159 37.5335 21.1092 37.5335 21.4754 37.8996C21.8416 38.2658 21.8416 38.8591 21.4754 39.2253L17.6003 43.1004C17.2341 43.4665 16.6408 43.4665 16.2746 43.1004Z"
                    fill="#706DF7"
                  />
                  <path
                    d="M34.976 30.0234C34.678 29.7254 34.1951 29.7254 33.8971 30.0234L33.2484 30.6725C32.3992 30.2721 31.3606 30.4022 30.6604 31.1023C30.2283 31.5345 29.9898 32.1097 29.9898 32.7214C29.9898 33.3324 30.2283 33.9076 30.6604 34.3397C30.9584 34.637 30.9584 35.1213 30.6604 35.4186C30.3624 35.7166 29.8796 35.7166 29.5815 35.4186C29.437 35.2741 29.358 35.0826 29.358 34.8792C29.358 34.4575 29.0167 34.1162 28.595 34.1162C28.1733 34.1162 27.832 34.4575 27.832 34.8792C27.832 35.2254 27.9163 35.5563 28.0612 35.86L27.4237 36.4976C27.1257 36.7956 27.1257 37.2784 27.4237 37.5764C27.7217 37.8745 28.2045 37.8745 28.5026 37.5764L29.1512 36.9279C30.1385 37.3932 31.1184 37.1195 31.7392 36.4976C32.5573 35.6795 32.5884 34.4102 31.907 33.5137C31.8697 33.4217 31.8138 33.3355 31.7392 33.261C31.4417 32.9634 31.4401 32.4812 31.7392 32.1821C32.0372 31.8855 32.5201 31.8848 32.8181 32.1829C32.9627 32.3259 33.0417 32.5174 33.0417 32.7216C33.0417 33.1433 33.3829 33.4845 33.8046 33.4845C34.2264 33.4845 34.5676 33.1433 34.5676 32.7216C34.5676 32.3747 34.4834 32.0436 34.3387 31.7402L34.9759 31.1025C35.274 30.8043 35.274 30.3207 34.976 30.0234Z"
                    fill="white"
                  />
                  <defs>
                    <filter
                      id="filter0_bi"
                      x="19.6368"
                      y="22.8182"
                      width="22.6699"
                      height="22.6645"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImage"
                        stdDeviation="1.45455"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.145455" dy="0.0727273" />
                      <feGaussianBlur stdDeviation="0.363637" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow"
                      />
                    </filter>
                    <filter
                      id="filter1_bi"
                      x="19.6368"
                      y="22.8182"
                      width="22.6699"
                      height="22.6645"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feGaussianBlur
                        in="BackgroundImage"
                        stdDeviation="1.45455"
                      />
                      <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="0.145455" dy="0.0727273" />
                      <feGaussianBlur stdDeviation="0.363637" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2={-1}
                        k3={1}
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className="featureHeadingTextContent">
                <h3>Secure and Compliant </h3>
                <p>
                  LaveStarter ensures projects launching through the platform
                  maintain the highest security standards (token vesting, locked
                  liquidity…) and are fully compliant.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mobileViewSection">
          <div
            id="ourKeyFeatureSliders"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#ourKeyFeatureSliders"
                data-slide-to={0}
                className="active"
              />
              <li data-target="#ourKeyFeatureSliders" data-slide-to={1} />
              <li data-target="#ourKeyFeatureSliders" data-slide-to={2} />
            </ol>
            <div className="carousel-inner keyFeatursPoints">
              <div className="carousel-item active">
                <div className="featureTextContent">
                  <div className="featureCircle">
                    {/* <img src={require("../../images/FairDecentralized.png")} alt=""> */}
                    <svg
                      width={64}
                      height={64}
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="-0.000976562"
                        y="-0.000488281"
                        width={64}
                        height={64}
                        rx={32}
                        fill="white"
                      />
                      <path
                        d="M37.6752 29.9123C39.2333 30.8763 39.2333 33.1227 37.6752 34.0868L15.8049 47.6192C14.0025 48.7344 11.7092 47.2414 12.0294 45.1611L13.9981 32.3703C14.036 32.1245 14.036 31.8745 13.9981 31.6287L12.0294 18.8379C11.7092 16.7576 14.0025 15.2646 15.8049 16.3799L37.6752 29.9123Z"
                        fill="#706DF7"
                      />
                      <g filter="url(#filter0_bi)">
                        <path
                          d="M50.8305 29.9123C52.3885 30.8763 52.3885 33.1227 50.8305 34.0868L28.9602 47.6192C27.1578 48.7344 24.8645 47.2414 25.1847 45.1611L27.1534 32.3703C27.1912 32.1245 27.1912 31.8745 27.1534 31.6287L25.1847 18.8379C24.8645 16.7576 27.1578 15.2646 28.9602 16.3799L50.8305 29.9123Z"
                          fill="#FFA78D"
                          fillOpacity="0.5"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_bi"
                          x="21.1543"
                          y="11.9995"
                          width="34.8445"
                          height={40}
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity={0}
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImage"
                            stdDeviation={2}
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="0.2" dy="0.1" />
                          <feGaussianBlur stdDeviation="0.5" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2={-1}
                            k3={1}
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect2_innerShadow"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="featureHeadingTextContent">
                    <h3>Fair and Decentralized Fundraise </h3>
                    <p>
                      With fundraising being a crucial step to fuel product
                      growth and development, we provide the best decentralized
                      fundraising platform for project’s ideas to transform into
                      reality
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="featureTextContent">
                  <div className="featureCircle">
                    {/* <img src={require("../../images/AccessToIDOs.png")} alt=""> */}
                    <svg
                      width={64}
                      height={65}
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="-0.000976562"
                        y="0.998779"
                        width={64}
                        height={64}
                        rx={32}
                        fill="white"
                      />
                      <g filter="url(#filter0_bi)">
                        <path
                          d="M48.5457 20.6273C48.9854 18.7221 47.2779 17.0146 45.3727 17.4543L18.6294 23.6258C16.4254 24.1345 15.8345 26.995 17.6566 28.3353L28.8603 36.576C29.0756 36.7344 29.2656 36.9244 29.424 37.1397L37.6647 48.3434C39.005 50.1655 41.8655 49.5746 42.3742 47.3706L48.5457 20.6273Z"
                          fill="#FFA78D"
                          fillOpacity="0.5"
                        />
                      </g>
                      <g filter="url(#filter1_bi)">
                        <path
                          d="M35.0851 30.7921C32.994 28.701 29.6037 28.701 27.5126 30.7921L17.7865 40.5182C15.6954 42.6093 15.6954 45.9996 17.7865 48.0907C19.8776 50.1818 23.2679 50.1818 25.359 48.0907L35.0851 38.3646C37.1762 36.2735 37.1762 32.8832 35.0851 30.7921Z"
                          fill="#706DF7"
                          fillOpacity="0.7"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_bi"
                          x="11.4766"
                          y={2}
                          width="52.5235"
                          height="52.5235"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity={0}
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImage"
                            stdDeviation={2}
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="0.2" dy="0.1" />
                          <feGaussianBlur stdDeviation="0.5" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2={-1}
                            k3={1}
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect2_innerShadow"
                          />
                        </filter>
                        <filter
                          id="filter1_bi"
                          x={10}
                          y="23.0059"
                          width="32.8711"
                          height="32.8711"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity={0}
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImage"
                            stdDeviation={2}
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="0.2" dy="0.1" />
                          <feGaussianBlur stdDeviation="0.5" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2={-1}
                            k3={1}
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect2_innerShadow"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="featureHeadingTextContent">
                    <h3>Access to IDOs of Lavandos Projects </h3>
                    <p>
                      Providing a user friendly gateway for users to seamlessly
                      access and invest in well-vetted projects building in the
                      LAVE Ecosystem
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="featureTextContent">
                  <div className="featureCircle">
                    {/* <img src={require("../../images/LotteryTiers.png")} alt=""> */}
                    <svg
                      width={64}
                      height={65}
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width={64}
                        height={64}
                        rx={32}
                        transform="matrix(-1 0 0 1 63.998 0.998779)"
                        fill="white"
                      />
                      <path
                        d="M32.0003 43.9091L19.8862 31.8543C19.0037 30.9718 18.5166 29.7944 18.5166 28.541C18.5166 27.2922 19 26.1194 19.8771 25.2359C19.6739 23.8232 20.1152 22.3346 21.1991 21.2497C21.2009 21.2479 21.2028 21.246 21.2046 21.2433C21.0087 19.8352 21.45 18.3548 22.5303 17.2744C22.8965 16.9082 23.4898 16.9091 23.856 17.2735L32.3137 25.7111C32.5884 25.9866 40.8869 33.7348 40.7276 34.0909C40.5665 34.448 40.3921 35.5729 40.0003 35.5455C38.0905 35.4301 41.3297 38.9444 40.0003 40.2728C38.6709 41.6003 36.9704 39.1048 37.0912 41C37.1151 41.39 36.3574 43.0216 36.0003 43.1818C35.6555 43.3388 32.2813 44.1916 32.0003 43.9091Z"
                        fill="#706DF7"
                      />
                      <path
                        d="M36.4593 46.4846C35.205 46.4846 34.0276 45.9975 33.1432 45.1131L29.9956 41.9829C29.7191 41.7073 29.6422 41.2898 29.8016 40.9337C29.9627 40.5776 30.3252 40.3679 30.7152 40.3826C32.5939 40.5053 34.4598 39.8095 35.7873 38.481C37.1167 37.1526 37.8106 35.3041 37.6897 33.409C37.6659 33.019 37.8838 32.6555 38.239 32.4953C38.5961 32.3332 38.9984 31.9972 39.2731 32.2727L47.7258 41.1443C48.092 41.5105 48.092 42.1039 47.7258 42.4691C46.6473 43.5485 45.1788 43.9953 43.756 43.7948C43.7542 43.7966 43.7523 43.7985 43.7505 43.8003C42.6665 44.8834 41.1889 45.3274 39.7643 45.1232C38.8818 46.0012 37.7099 46.4846 36.4593 46.4846Z"
                        fill="#706DF7"
                      />
                      <g filter="url(#filter0_bi)">
                        <g filter="url(#filter1_bi)">
                          <path
                            d="M25.0106 40.1105C21.7075 36.8074 21.7412 31.4553 25.0106 28.1912C28.2516 24.9467 33.6055 24.8656 36.929 28.1912C40.6338 31.896 39.7796 37.263 36.929 40.1105C33.6982 43.3414 28.3497 43.4475 25.0106 40.1105Z"
                            fill="#FFA78D"
                            fillOpacity="0.5"
                          />
                        </g>
                      </g>
                      <path
                        d="M16.2746 48.7254C15.9085 48.3592 15.9085 47.7659 16.2746 47.3997L22.0247 41.6496C22.3909 41.2835 22.9842 41.2835 23.3504 41.6496C23.7166 42.0158 23.7166 42.6091 23.3504 42.9753L17.6003 48.7254C17.2341 49.0916 16.6408 49.0916 16.2746 48.7254Z"
                        fill="#706DF7"
                      />
                      <path
                        d="M21.8996 48.7254C21.5335 48.3592 21.5335 47.7658 21.8996 47.3997L25.7747 43.5246C26.1409 43.1585 26.7342 43.1585 27.1004 43.5246C27.4665 43.8908 27.4665 44.4841 27.1004 44.8503L23.2253 48.7254C22.8591 49.0915 22.2658 49.0915 21.8996 48.7254Z"
                        fill="#706DF7"
                      />
                      <path
                        d="M16.2746 43.1004C15.9085 42.7342 15.9085 42.1408 16.2746 41.7747L20.1497 37.8996C20.5159 37.5335 21.1092 37.5335 21.4754 37.8996C21.8416 38.2658 21.8416 38.8591 21.4754 39.2253L17.6003 43.1004C17.2341 43.4665 16.6408 43.4665 16.2746 43.1004Z"
                        fill="#706DF7"
                      />
                      <path
                        d="M34.976 30.0234C34.678 29.7254 34.1951 29.7254 33.8971 30.0234L33.2484 30.6725C32.3992 30.2721 31.3606 30.4022 30.6604 31.1023C30.2283 31.5345 29.9898 32.1097 29.9898 32.7214C29.9898 33.3324 30.2283 33.9076 30.6604 34.3397C30.9584 34.637 30.9584 35.1213 30.6604 35.4186C30.3624 35.7166 29.8796 35.7166 29.5815 35.4186C29.437 35.2741 29.358 35.0826 29.358 34.8792C29.358 34.4575 29.0167 34.1162 28.595 34.1162C28.1733 34.1162 27.832 34.4575 27.832 34.8792C27.832 35.2254 27.9163 35.5563 28.0612 35.86L27.4237 36.4976C27.1257 36.7956 27.1257 37.2784 27.4237 37.5764C27.7217 37.8745 28.2045 37.8745 28.5026 37.5764L29.1512 36.9279C30.1385 37.3932 31.1184 37.1195 31.7392 36.4976C32.5573 35.6795 32.5884 34.4102 31.907 33.5137C31.8697 33.4217 31.8138 33.3355 31.7392 33.261C31.4417 32.9634 31.4401 32.4812 31.7392 32.1821C32.0372 31.8855 32.5201 31.8848 32.8181 32.1829C32.9627 32.3259 33.0417 32.5174 33.0417 32.7216C33.0417 33.1433 33.3829 33.4845 33.8046 33.4845C34.2264 33.4845 34.5676 33.1433 34.5676 32.7216C34.5676 32.3747 34.4834 32.0436 34.3387 31.7402L34.9759 31.1025C35.274 30.8043 35.274 30.3207 34.976 30.0234Z"
                        fill="white"
                      />
                      <defs>
                        <filter
                          id="filter0_bi"
                          x="19.6368"
                          y="22.8182"
                          width="22.6699"
                          height="22.6645"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity={0}
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImage"
                            stdDeviation="1.45455"
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="0.145455" dy="0.0727273" />
                          <feGaussianBlur stdDeviation="0.363637" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2={-1}
                            k3={1}
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect2_innerShadow"
                          />
                        </filter>
                        <filter
                          id="filter1_bi"
                          x="19.6368"
                          y="22.8182"
                          width="22.6699"
                          height="22.6645"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity={0}
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImage"
                            stdDeviation="1.45455"
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="0.145455" dy="0.0727273" />
                          <feGaussianBlur stdDeviation="0.363637" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2={-1}
                            k3={1}
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect2_innerShadow"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                  <div className="featureHeadingTextContent">
                    <h3>Secure and Compliant </h3>
                    <p>
                      LaveStarter ensures projects launching through the
                      platform maintain the highest security standards (token
                      vesting, locked liquidity…) and are fully compliant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="synergizingSection">
    <div className="syergizingSecton_overlay" />
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="synergizingTextContent">
            <h2>
              The role of the LaveStarter
              <br className="removeBrTag" /> in the LAVE ecosystem
            </h2>
            <p>
              Launching a LaveStarter will give development to the LAVE
              ecosystem.
              <br className="removeBrTag" /> Lavestarter will provide an
              opportunity for enthusiasts, programmers, artists, creative people
              to realize their ideas and thereby become part of the Lave
              ecosystem.
            </p>
            {/* <a href="">Learn More <i class="fa fa-angle-right"></i></a> */}
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <div className="LaveStarterSynergizingSection LaveStarterSection">
                <div className="LaveStarterSynergizingImg">
                  <img
                    src={POLYGON}
                    alt=""
                    style={{ width: 130 }}
                  />
                </div>
                <div className="LaveStarterSynergizingContents">
                  <h3>LAVE</h3>
                  <ul>
                    <li>
                      {" "}
                      <img
                        src={PolygonBullet_icon}
                        alt=""
                      />{" "}
                      Strong Infrastructure
                    </li>
                    <li>
                      {" "}
                      <img
                        src={PolygonBullet_icon_2}
                        alt=""
                      />{" "}
                      Faster Finality
                    </li>
                    <li>
                      {" "}
                      <img
                        src={PolygonBullet_icon}
                        alt=""
                      />{" "}
                      Low Cost
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="LaveStarterSynergizingSection LaveStarterSection">
                <div className="LaveStarterSynergizingImg">
                  <img
                    src={POLYSTARTER}
                    alt=""
                    style={{ width: 130 }}
                  />
                </div>
                <div className="LaveStarterSynergizingContents">
                  <h3>LaveStarter</h3>
                  <ul>
                    <li>
                      {" "}
                      <img
                        src={PolygonBullet_icon}
                        alt=""
                      />{" "}
                      IDO Launchpad
                    </li>
                    <li>
                      {" "}
                      <img
                        src={PolygonBullet_icon_2}
                        alt=""
                      />{" "}
                      Comprehensive Support
                    </li>
                    <li>
                      {" "}
                      <img
                        src={PolygonBullet_icon}
                        alt=""
                      />{" "}
                      Seamless Experience
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="col-lg-6">
              <div class="synergizingImageSection">
                  <img src={require("../../images/synergizingLaveStarter.png")} alt="">
              </div>
          </div> */}
      </div>
    </div>
  </div>
  <div className="findTheRightPlan" id="FindTheRightPlan">
    <div className="findTheRightPlan_overlay" />
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="findRightHeading">
            <h2>
              Built <span>For</span>
            </h2>
            {/* <p>Flexible pricing options for freelancers and<br class="removeBrTag" /> design teams.</p> */}
          </div>
        </div>
      </div>
    </div>
    <div className="rightPlanCard">
      <div className="rightPlanCard_overlay">
        <svg
          viewBox="0 0 1440 325"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-68.9104 323.902L-36.1167 321.391C-3.32306 318.879 62.2643 313.857 127.852 308.834C193.439 303.812 259.026 298.789 320.924 245.583C382.905 193.472 441.281 94.2732 504.436 57.4934C567.592 20.7136 635.611 47.4481 703.63 74.1827C771.649 100.917 839.585 126.557 905.843 130.295C972.017 132.938 1036.35 111.489 1099.59 75.8042C1162.74 39.0244 1224.72 -13.0864 1292.07 4.88755C1359.5 23.9566 1432.3 113.11 1468.7 157.687L1505.19 203.359"
            stroke="#FAB8C4"
            strokeWidth={2}
          />
        </svg>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="rightplanBox planOneBox">
              {/* <img src={require("../../images/ForTeam.png")} alt=""> */}
              <h3>For Teams</h3>
              <ul>
                <li>Permissionless listing</li>
                <li>Fair &amp; decentralized fundraising</li>
                <li>Various token auction types</li>
                <li>Access to a suite of products under LaveStarter</li>
                <li>Marketing, advisory and technical support</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="rightplanBox planTwoBox">
              {/* <img src={require("../../images/ForCommunities.png")} alt=""> */}
              <h3>For Communities</h3>
              <ul>
                <li>Access to public token launches of Lavandos projects</li>
                <li>Low gas fee and fast transactions</li>
                <li>Support multiple currencies and stablecoin</li>
                <li>Lottery tiers that fit everyone</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="rightplanBox planThreeBox">
              <h3>For venture capital</h3>
              <ul>
                <li>Access to well-vetted projects</li>
                <li>Secure &amp; compliant</li>
                <li>Token vesting and distribution</li>
                <li>Token claim dashboard</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="roadMapSection" style={{ display: "none" }}>
    <div className="roadMapSection_overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-3" />
        <div className="col-md-9">
          <div className="roadMapHeading">
            <div className="roadMapHeading_overlay">
              <span />
            </div>
            <h2>Roadmap</h2>
            <p>
              Upcoming milestones in LaveStarter’s path to become the
              <br className="removeBrTag" /> reference launchpad for LAVE based
              projects.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="roadMapContent">
        <div className="row">
          <div className="col-md-8" />
          <div className="col-md-4">
            <div className="roadMapTextContent firstRoadMap">
              <div className="indicateLine"></div>
              <h4>
                <img src={Q2_2021} alt="" /> Q1 &amp; Q2 2021
              </h4>
              <ul>
                <li>Ideation &amp; model testing</li>
                <li>Build MVP</li>
                <li>TON base TON swaps</li>
                <li>Fixed pools</li>
                <li>Public launch and TGE</li>
                <li>Lottery Module</li>
                <li>Liquidity mining</li>
                <li>Staking for pool access</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row RoadMaprightSide">
          <div className="col-md-4">
            <div className="roadMapTextContent secondRoadMap">
              <div className="indicateLine" />
              <h4>
                <img src={Q3_2021} alt="" /> Q3 2021
              </h4>
              <ul>
                <li>Multiple vesting options</li>
                <li>Auction module</li>
                <li>NFT Auctions</li>
                <li>Whitelisting module</li>
                {/* <li>LaveStarter</li> */}
                <li>Governance structure initialization</li>
              </ul>
            </div>
          </div>
          <div className="col-md-8" />
        </div>
        <div className="row">
          <div className="col-md-8" />
          <div className="col-md-4">
            <div className="roadMapTextContent thriedRoadMap">
              <div className="indicateLine" />
              <h4>
                <img src={Q4_2021} alt="" /> Q4 2021
              </h4>
              <ul>
                <li>Multi-chain swaps</li>
                <li>DeFi modules</li>
                <li>LaveStarter governance &amp; DAO</li>
                <li>OTC Deal integration</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ourTeamSection" id="TeamSections" style={{ display: "none" }}>
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="TeamTextContent">
            <h2>
              Our <span>Team</span>
            </h2>
            <p>
              We listen, we discuss, we advise
              <br className="removeBrTag" /> and develop.
            </p>
            {/* <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item">
                          <a class="nav-link active" data-toggle="tab" href="#Team" role="tab">Team</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" data-toggle="tab" href="#Advisor" role="tab">Advisor</a>
                      </li>
                  </ul> */}
          </div>
        </div>
        <div className="col-lg-7">
          <div className="TeamMembersSection">
            <div className="tab-content">
              <div className="tab-pane active" id="Team" role="tabpanel">
                <div
                  id="carousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carousel"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carousel" data-slide-to={1} />
                  </ol>
                  {/* <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Previous</span>
                              </a>
                              <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
                                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span class="sr-only">Next</span>
                              </a> */}
                </div>
              </div>
              {/* <div class="tab-pane" id="Advisor" role="tabpanel">
                          <div class="row">
                              <div class="col-md-6">
                                  <div class="memberCardBox">
                                      <h4>John Lilic</h4>
                                      <div class="TeamMember_img">
                                          <img src={require("../../images/Team/John_Lilic.jpg)}" alt="">
                                      </div>
                                      <p>Technical Advisor</p>
                                      <ul>
                                          <li><a href="https://twitter.com/JohnLilic" target="_blank"><i class="fa fa-twitter"></i></a></li>
                                          <li><a href="https://www.linkedin.com/in/jlilic/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                                      </ul>
                                  </div>
                              </div>


                          </div>
                      </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="footerLogoSection">
            <img src={footerLogo} alt="" />
            <p>
              LaveStarter is the first native Decentralized Launchpad for
              projects building on LAVE ecosystem, with a strong emphasis on
              providing equitable opportunities to the community without the
              constraints of centralized platforms or high transaction costs.
            </p>
            <ul>
              <li>
                <a href="https://twitter.com/lave_token" target="_blank">
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://t.me/Lave_eng" target="_blank">
                  <i className="fa fa-paper-plane" />
                </a>
              </li>
              <li>
                <a href="https://LaveStarter.medium.com/" target="_blank">
                  <i className="fa fa-medium" />
                </a>
              </li>
              {/* <li><a href="https://www.linkedin.com/company/LaveStarterofficial/?viewAsMember=true" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                      <li><a href="https://www.facebook.com/LaveStarter.Official/?ref=pages_you_manage" target="_blank"><i class="fa fa-facebook"></i></a></li>
                      <li><a href="https://www.instagram.com/LaveStarter/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                      <li><a href="https://www.reddit.com/user/LaveStarterOfficial" target="_blank"><i class="fa fa-reddit"></i></a></li> */}
              <li>FOLLOW</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-sm-6">
              <div className="footerMenuSeciton">
                <h2>General</h2>
                <ul>
                  <li>
                    <a href="/">About</a>
                  </li>
                  <li>
                    <a href="/">Contact</a>
                  </li>
                  <li>
                    <a href="/">Whitepaper</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="col-sm-4">
                      <div class="footerMenuSeciton">
                          <h2>Resource</h2>
                          <ul>
                              <li><a href="">About</a></li>
                              <li><a href="">Whitepaper</a></li>
                              <li><a href="">Go to the app</a></li>
                          </ul>
                      </div>
                  </div> */}
            <div className="col-sm-6">
              <div className="footerMenuSeciton">
                <h2>Community</h2>
                <ul>
                  <li>
                    <a href="https://twitter.com/Lave_token" target="_blank">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://LaveStarter.medium.com/" target="_blank">
                      Medium
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/LaveStarter" target="_blank">
                      Telegram
                    </a>
                  </li>
                  <li>
                    <a
                      href=" https://www.reddit.com/user/LaveStarter "
                      target=" _blank "
                    >
                      Reddit
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className=" container ">
      <div className=" miniFooterSection ">
        <p>© 2021 LaveStarter Ltd. All Rights Reserved</p>
      </div>
    </div>
  </footer>
</>
  );
};
