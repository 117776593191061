import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Balance": "Balance",
      "Copy": "Copy",
      "Error": "Error",
      "Your TON address": "Your TON address",
      "Send": "Send",
      Withdraw: "Withdraw",
      Deposit: "Deposit",
      Play: "Play",
      History: "History",
      Settings: "Settings",
      Search: "Search",
      "Enter receiver address": "Enter receiver address",
      Amount: "Amount",
      Comission: "Comission",
      "TON deposit from 0.1 TON, deposit with any jettons - unlimited.":
        "TON deposit from 0.1 TON, deposit with any jettons - unlimited.",
      "Receive Toncoin":
        "Receive Toncoin",
      "Send only TONCOIN to this address (Network: TON). Other coins will not reach the balance and will be lost      ": "Send only TONCOIN to this address (Network: TON). Other coins will not reach the balance and will be lost",
      Swap: "Swap",
      Cheque: "Cheque",
      Invoice: "Invoice",
      Buy: "Buy",
      "XAPPS UPDATES": "XAPPS UPDATES",
      "Show all": "Show all",
      From: "From",
      To: "To",
      "Amount of cryptocurrency to receive is calculated only after the payment is processed. Minimum amount for deposit is 5 RUB.":
        "Amount of cryptocurrency to receive is calculated only after the payment is processed. Minimum amount for deposit is 5 RUB.",
      "I agree to": "I agree to",
      "terms of use": "terms of use",
      "Buy with a card": "Buy with a card",
      Wallet: "Wallet",
      Language: "Language",
      Currency: "Currency",
      OTHER: "OTHER",
      Support: "Support",
      Channel: "Channel",
      "Powered by": "Powered by",
      English: "English",
      Russian: "Russian",
      "You don't have NFTs!": "You don't have NFTs!",
      "Scan token": "Scan token",
      "You don't have enough TON": "You don't have enough TON",
      Sending: "Sending",
      Max: "Max",
      "Fiat Exchange": "Fiat Exchange",
      "Creating a payment": "Creating a payment",
      "Waiting for details": "Waiting for details",
      "Open in browser": "Open in browser",
      "Waiting for details. Created payment with id ":
        "Waiting for details. Created payment with id ",
      Collection: "Collection",
      Back: "Back",
      "Check deposit": "Check deposit",
      "Waiting for detail": "Waiting for details",
      Sell: "Sell",
      "Successfully sold for": "Successfully sold for",
      "Your history is empty. Send or receive tokens to see it here.": "Your history is empty. Send or receive tokens to see it here.",
      "Loading..": "Loading..",
      "Order successfully created": "Order successfully created",
      "In a few minutes the funds will arrive on your balance": "In a few minutes the funds will arrive on your balance",
      "My tickets": "My tickets",
      "Referrals": "Referrals",
      "How to send crypto": "How to send crypto",
      "External wallet": "External wallet",
      "Send TON to external address": "Send TON to external address",
      "Next": "Next",
      "Enter your TON address": "Enter your TON address",
      "Duel": "Duel",
      "Fast": "Fast",
      "Choose play mode": "Choose play mode",
      "Win 2 TON in a one-on-one drawing": "Win 2 TON in a one-on-one drawing",
      "Win from 10 to 1000 TON in a raffle for 10 participants": "Win from 10 to 1000 TON in a raffle for 10 participants",
      "Kick off 2024 with our Grand New Year's Raffle this January! Participate with a simple click and a test transaction. Don't miss out on the excitement!": "Kick off 2024 with our Grand New Year's Raffle this January! Participate with a simple click and a test transaction. Don't miss out on the excitement!",
      "New Year's raffle": "New Year's raffle",
      "Grand New Year's raffle and lots of prizes": "Grand New Year's raffle and lots of prizes",
      "Welcome to the duel.\nThis drawing is for two players with a 50/50 chance of winning.\nThe draw will automatically start when the tickets are sold.\nRight after that we will determine the sole winner🏆.\nWe wish you the best of luck! 🥳✨": "Welcome to the duel.\nThis drawing is for two players with a 50/50 chance of winning.\nThe draw will automatically start when the tickets are sold.\nRight after that we will determine the sole winner🏆.\nWe wish you the best of luck! 🥳✨",
      "GRAND PRIZE ": "GRAND PRIZE ",
      "Ticket price ": "Ticket price ",
      "Welcome to the quick draws.\nThere are 10 players in this draw.\n🎫Participant in the draw can only purchase 1 ticket.\nThe raffle will automatically start when the tickets are sold.\nRight after that we will determine the only winner🏆.\nWe wish you the best of luck! 🥳✨": "Welcome to the quick draws.\nThere are 10 players in this draw.\n🎫Participant in the draw can only purchase 1 ticket.\nThe raffle will automatically start when the tickets are sold.\nRight after that we will determine the only winner🏆.\nWe wish you the best of luck! 🥳✨",
      "Select the size of the ton prize for the drawing, you wish to participate in.": "Select the size of the ton prize for the drawing, you wish to participate in.",
      "Buy more tickets": "Buy more tickets",
      "Congratulations! You have just purchased": "Congratulations! You have just purchased",
      "ticket(s) for the №": "ticket(s) for the №",
      "drawing.\nYou can view the ticket numbers in the 'My Tickets' menu\nIn case you are a winner, we will notify you immediately and automatically credit your winnings to your Panda Loto wallet.\nGood luck and may your lucky ticket bring you a win! 🎉💸": "drawing.\nYou can view the ticket numbers in the 'My Tickets' menu\nIn case you are a winner, we will notify you immediately and automatically credit your winnings to your Panda Loto wallet.\nGood luck and may your lucky ticket bring you a win! 🎉💸",
      "You don't have enough funds to buy this ticket.": "You don't have enough funds to buy this ticket.",
      "Ticket count: ": "Ticket count: ",
      "All": "All",
      "Won": "Won",
      "Active": "Active",
      "Passed": "Passed",
      "The ticket is awaiting its drawing.\nWe wish you good luck! 🥳✨": "The ticket is awaiting its drawing.\nWe wish you good luck! 🥳✨",
      "Possible win:": "Possible win:",
      "Ticket №: ": "Ticket №: ",
      "Raffle №: ": "Raffle №: ",
      "Raffle type: ": "Raffle type: ",
      "Successfully": "Successfully",
      "sent": "sent",
      "Ticket on raffle bought: ": "Ticket on raffle bought: ",
      "Congratulations. The winning ticket totaled ": "Congratulations. The winning ticket totaled ",
      "Insufficient balance": "Insufficient balance",
      "Amount must be at least 1": "Amount must be at least 1",
      "Game not found": "Game not found",
      "Game is not active": "Game is not active",
      "Prize fund mismatch": "Prize fund mismatch",
      "Fast and Duel games ticket can only be bought once": "Fast and Duel games ticket can only be bought once",
      "Classic games ticket can only be bought 30 tickets": "Classic games ticket can only be bought 30 tickets",
      "Maximum ticket amount reached": "Maximum ticket amount reached",
      " from ": " from ",
      "You don't have any tickets here, but don't worry you can easily buy it": "You don't have any tickets here, but don't worry you can easily buy it",
      "Buy Tickets": "Buy Tickets",
      "duel": "duel",
      "fast": "fast",
      "classic": "classic",
      "Not enough balance": "Not enough balance",
      "You can't send coins to yourself": "You can't send coins to yourself",
      "Current language: english": "Current language: english",
      "Classic": "Classic",
      "Win from 100 to 10000 TON in a drawing for 1000 tickets": "Win from 100 to 10000 TON in a drawing for 1000 tickets",
      "https://telegra.ph/Draw-Rules-09-02": "https://telegra.ph/Draw-Rules-09-02",
      "Rules": "Rules",
      "Sent": "Sent",
      "Sent to User": "Sent to User",
      "Sent from User": "Sent from User",
      "Received": "Received",
      "Lottery win": "Lottery win",
      "Instruction": "Instruction",
      "Congratulations on purchasing a ticket for Drawing № ": "Congratulations on purchasing a ticket for Drawing № ",
      'Tickets are available in the "My Tickets" section.\nIf you win, we will automatically transfer your winnings to your Panda Loto wallet and notify you.\nGood luck and we hope your ticket brings in the winnings! 🎉': 'Tickets are available in the "My Tickets" section.\nIf you win, we will automatically transfer your winnings to your Panda Loto wallet and notify you.\nGood luck and we hope your ticket brings in the winnings! 🎉',
      "Ticket buy": "Ticket buy",
      "Tickets bought ": "Tickets bought "
    },
  },
  ru: {
    translation: {
      "Balance": "Баланс",
      Withdraw: "Вывести",
      Deposit: "Пополнить",
      Play: "Играть",
      History: "История",
      Settings: "Настройки",
      Search: "Поиск",
      "Send": "Отправить",
      "Enter receiver address": "Введите адрес получателя",
      "Copy": "Копировать",
      Amount: "Количество",
      Comission: "Комиссия",
      "TON deposit from 0.1 TON, deposit with any jettons - unlimited.":
        "Минимальное пополнение – 0.1 TON, депозит в любых токенах (Jetton) - неограничен. После пополнения нажмите кнопку проверить пополнение.",
      "Receive Toncoin":
        "Получить Toncoin",
      "Your TON address": "Ваш адрес TON",
      Swap: "Обмены",
      Cheque: "Чеки",
      Invoice: "Счета",
      Buy: "Купить",
      "XAPPS UPDATES": "ОБНОВЛЕНИЯ",
      "Show all": "Показать все",
      From: "Откуда",
      To: "Куда",
      "Amount of cryptocurrency to receive is calculated only after the payment is processed. Minimum amount for deposit is 5 RUB.":
        "Сумма криптовалюты для получения рассчитывается только после обработки платежа. Минимальная сумма для внесения депозита составляет 5 рублей.",
      "I agree to": "Я согласен с",
      "terms of use": " условиями использования",
      "Buy with a card": "Покупайте с помощью карты",
      Wallet: "Кошелек",
      Language: "Язык",
      Currency: "Валюта",
      OTHER: "ПРОЧИЕ",
      Support: "Поддержка",
      Channel: "Канал",
      English: "Английский",
      Russian: "Русский",
      "You don't have any NFT. Try transferring it to your deposit wallet and it will show up here.": "У Вас нет ни одного NFT. Попробуйте перевести его на Ваш депозитный кошелек и он отобразится здесь.",
      "Scan token": "Сканирование токена",
      "You don't have enough TON": "Вам не хватает ТОН",
      Sending: "Отправка",
      Max: "Макс.",
      "Fiat Exchange": "Обмен валюты",
      "Creating a payment": "Создание платежа",
      "Waiting for details": "Ожидание реквизитов",
      "Open in browser": "Открыть в браузере",
      "Waiting for details. Created payment with ID ":
        "Ожидание реквизитов. Создан платеж с ID  ",
      Collection: "Коллекция",
      Back: "Назад",
      "Check deposit": "Проверить депозит",
      "Waiting for detail": "В ожидании подробностей",
      Sell: "Продать",
      "Successfully sold for": "Успешно продано за",
      "Error": "Ошибка",
      "Your history is empty. Send or receive tokens to see it here.": "Ваша история транзакций пуста. Отправьте или получите жетоны, чтобы увидеть ее здесь.",
      "Loading..": "Загрузка..",
      "Order successfully created": "Заявка успешно создана",
      "In a few minutes the funds will arrive on your balance": "В течение нескольких минут средства поступят на ваш баланс",
      "Send only TONCOIN to this address (Network: TON). Other coins will not reach the balance and will be lost": "Отправляйте на этот адрес только TONCOIN (сеть: TON). Другие монеты не поступят на баланс и будут потеряны",
      "My tickets": "Мои билеты",
      "Referrals": "Рефералы",
      "How to send crypto": "Как отправить криптовалюту",
      "External wallet": "Внешний кошелек",
      "Send TON to external address": "Отправить TON на внешний адрес",
      "Next": "Далее",
      "Enter your TON address": "Введите ваш TON адрес",
      "Duel": "Дуэль",
      "Fast": "Быстрые",
      "Choose play mode": "Выберите режим игры",
      "Win 2 TON in a one-on-one drawing": "Выиграйте 2 TON в розыгрыше один на один",
      "Win from 10 to 1000 TON in a raffle for 10 participants": "Выиграйте от 10 до 1000 TON в розыгрыше на 10 участников",
      "Kick off 2024 with our Grand New Year's Raffle this January! Participate with a simple click and a test transaction. Don't miss out on the excitement!": "Начните 2024 год с нашего большого новогоднего розыгрыша в январе! Участвуйте одним кликом и тестовой транзакцией. Не пропустите волнение!",
      "New Year's raffle": "Новогодний розыгрыш",
      "Grand New Year's raffle and lots of prizes": "Большой новогодний розыгрыш и множество призов",
      "Welcome to the duel.\nThis drawing is for two players with a 50/50 chance of winning.\nThe draw will automatically start when the tickets are sold.\nRight after that we will determine the sole winner🏆.\nWe wish you the best of luck! 🥳✨": "Добро пожаловать в дуэль.\nВ этом розыгрыше участвуют два игрока с шансом выигрыша 50/50.\nРозыгрыш автоматически начнется, когда билеты будут проданы.\nСразу после этого мы определим единственного победителя🏆.\nЖелаем удачи! 🥳✨",
      "GRAND PRIZE ": "ГЛАВНЫЙ ПРИЗ ",
      "Ticket price ": "Цена билета ",
      "Welcome to the quick draws.\nThere are 10 players in this draw.\n🎫Participant in the draw can only purchase 1 ticket.\nThe raffle will automatically start when the tickets are sold.\nRight after that we will determine the only winner🏆.\nWe wish you the best of luck! 🥳✨": "Добро пожаловать в быстрые розыгрыши.\nВ этом розыгрыше участвуют 10 игроков.\n🎫Участник розыгрыша может купить только 1 билет.\nРозыгрыш автоматически начнется, когда билеты будут проданы.\nСразу после этого мы определим единственного победителя🏆.\nЖелаем удачи! 🥳✨",
      "Select the size of the ton prize for the drawing, you wish to participate in.": "Выберите размер приза в тоннах для розыгрыша, в котором хотите участвовать.",
      "Buy more tickets": "Купить больше билетов",
      "Congratulations! You have just purchased": "Поздравляем! Вы только что приобрели",
      "ticket(s) for the №": "билет(ы) на №",
      "drawing.\nYou can view the ticket numbers in the 'My Tickets' menu\nIn case you are a winner, we will notify you immediately and automatically credit your winnings to your Panda Loto wallet.\nGood luck and may your lucky ticket bring you a win! 🎉💸": "розыгрыш.\nВы можете посмотреть номера билетов в меню 'Мои билеты'\nЕсли вы выиграете, мы немедленно уведомим вас и автоматически зачислим ваши выигрыши на ваш кошелек Panda Loto.\nУдачи и пусть ваш счастливый билет принесет вам выигрыш! 🎉💸",
      "You don't have enough funds to buy this ticket.": "У вас недостаточно средств для покупки этого билета.",
      "Ticket count: ": "Количество билетов: ",
      "All": "Все",
      "Won": "Выиграно",
      "Active": "Активные",
      "Passed": "Прошедшие",
      "The ticket is awaiting its drawing.\nWe wish you good luck! 🥳✨": "Билет ожидает своего розыгрыша.\nЖелаем удачи! 🥳✨",
      "Possible win: ": "Возможный выигрыш: ",
      "Ticket №: ": "Билет №: ",
      "Raffle №: ": "Розыгрыш №: ",
      "Raffle type: ": "Тип розыгрыша: ",
      "Ticket on raffle bought: ": "Билетов на розыгрыш куплено: ",
      "Congratulations. The winning ticket totaled ": "Поздравляем. Выигрышный билет составил ",
      "Insufficient balance": "Недостаточный баланс",
      "Amount must be at least 1": "Сумма должна быть не менее 1",
      "Game not found": "Игра не найдена",
      "Game is not active": "Игра не активна",
      "Prize fund mismatch": "Несоответствие призового фонда",
      "Fast and Duel games ticket can only be bought once": "Билеты на быстрые и дуэльные игры можно купить только один раз",
      "Classic games ticket can only be bought 30 tickets": "На классические игры можно купить только 30 билетов",
      "Maximum ticket amount reached": "Достигнуто максимальное количество билетов",
      " from ": " из ",
      "You don't have any tickets here, but don't worry you can easily buy it": "У вас нет билетов, но не волнуйтесь, вы можете легко их купить",
      "Buy Tickets": "Купить билеты",
      "duel": "дуэль",
      "fast": "быстрый",
      "classic": "классический",
      "Successfully": "Успешно",
      "sent": "отправлено",
      "Not enough balance": "Недостаточно баланса",
      "You can't send coins to yourself": "Вы не можете отправить монеты самому себе",
      "Current language: english": "Текущий язык: русский",
      "Classic": "Классический",
      "Win from 100 to 10000 TON in a drawing for 1000 tickets": "Выиграйте от 100 до 10000 TON в розыгрыше на 1000 билетов",
      "https://telegra.ph/Draw-Rules-09-02": "https://telegra.ph/Pravila-Panda-Loto-09-02",
      "Rules": "Правила",
      "Sent": "Вывод",
      "Sent to User": "Отправлено пользователю",
      "Sent from User": "Пополнение от пользователя",
      "Received": "Пополнение",
      "Lottery win": "Победа в лото",
      "Instruction": "Инструкция",
      "Congratulations on purchasing a ticket for Drawing № ": "Поздравляем с покупкой билета на розыгрыш № ",
      'Tickets are available in the "My Tickets" section.\nIf you win, we will automatically transfer your winnings to your Panda Loto wallet and notify you.\nGood luck and we hope your ticket brings in the winnings! 🎉': 'Билеты доступны в разделе "Мои билеты".\nЕсли вы выиграете, мы автоматически переведем выигрыш на ваш кошелек Panda Loto и уведомим вас.\nУдачи и надеемся, что ваш билет принесет выигрыш! 🎉',
      "Ticket buy": "Купленный билет",
      "Tickets bought ": "Билетов куплено "
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
