import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./i18next";
import { router } from "./router";
import { TonConnectUIProvider } from '@tonconnect/ui-react';

export function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  global.Buffer = global.Buffer || require('buffer').Buffer

  useEffect(() => {
    if (
      window.location.pathname.includes("/swap")
    ) {
      router.navigate("/swap", {replace:true})
      return;
    }

    router.navigate("/", { replace: true });
  }, [dispatch, i18n]);

  return (
    <TonConnectUIProvider manifestUrl="https://lavestarter.org/manifest.json">
      <RouterProvider router={router} />
    </TonConnectUIProvider>
  );
}
